import { fetchCardsByOrderReference } from '../hooks/fetchCardsByOrderReference';
import { sendEmail } from './sendEmail';
import { cardTypes } from '../types/cardOrders/cardTypes';
import { generateHtmlContent } from '../components/dashboard/cardOrderDashboard/generateHtmlContent';

export const processEmailForCards = async (type: string, updateStatus: string, orderReference: string, email: string) => {
    const jsonResult = await fetchCardsByOrderReference(orderReference);
    const subjectContent = type === cardTypes.PREMIUM
        ? `Mail Kit pour votre Commande de Carte ${orderReference}`
        : `Votre Commande de Carte ${orderReference} est prête`;

    const htmlContent = generateHtmlContent(jsonResult, type === cardTypes.PREMIUM);
    await sendEmail(email, subjectContent, htmlContent, updateStatus, orderReference);
};
