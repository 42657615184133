import React, { useState } from 'react';
import { ListCards } from '../../../types/cardOrders/listCards';
import { StatusCardOrders } from '../../../types/cardOrders/statusCardOrders';
import { cardTypes } from '../../../types/cardOrders/cardTypes';
import { deleteCardsOrder } from './deleteCardsOrder';
import { processEmailForCards } from '../../../services/processEmailForCards';
import { exportCardsExcel } from './exportCardsExcel';
import { supabase } from '../../../services/supabaseClient';
import { fetchCardsByOrderReference } from '../../../hooks/fetchCardsByOrderReference';
import { updateCardOrderStatus } from '../../../services/UpdateCardOrderStatus';

interface OrderDetailsProps {
    orders: ListCards[];
}

const OrderDetails: React.FC<OrderDetailsProps> = ({ orders }) => {
    const [isInfoBoxOpen, setIsInfoBoxOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState<ListCards | null>(null);

    const basicsOrders = orders.filter(order => order.card_type === cardTypes.BASIC);
    const premiumsOrders = orders.filter(order => order.card_type === cardTypes.PREMIUM);
    const customOrders = orders.filter(order => order.order_reference !== null && order.order_reference.startsWith('custom#'));

    const allBasicsCreated = basicsOrders.length > 0 && basicsOrders.every(order => order.order_status === StatusCardOrders.CREATED);
    const allPremiumsMailKitSuccess = premiumsOrders.every(order => order.order_status === StatusCardOrders.MAIL_KIT_SUCCES);
    const hasMailKitError = premiumsOrders.some(order => order.order_status === StatusCardOrders.MAIL_KIT_ERROR);
    const anyPremiumsPresent = premiumsOrders.length > 0;
    const allCardsFinished = basicsOrders.every(order => order.order_status === StatusCardOrders.FINISH) && premiumsOrders.every(order => order.order_status === StatusCardOrders.FINISH);
    const hasMailKitPending = premiumsOrders.some(order => order.order_status === StatusCardOrders.MAIL_KIT_PENDING);
    const hasMailKitSent = premiumsOrders.some(order => order.order_status === StatusCardOrders.MAIL_KIT_SENT);
    const referenceCard = premiumsOrders[0]?.order_reference || basicsOrders[0]?.order_reference;
    const hasDeleteCards = orders.some(order => order.order_status === StatusCardOrders.DELETE);
    const hasCustomOrders = orders.some(order => order.order_reference !== null && order.order_reference.startsWith('custom#'));

    const renderTable = (title: string, orders: ListCards[]) => (
        <>
            <h3>{title}</h3>
            <table className="order-table">
                <thead>
                    <tr>
                        <th>Type de carte</th>
                        <th>Card ID</th>
                        <th>Status</th>
                        <th>Action</th>
                        <th>Ref</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map(order => (
                        <OrderRow key={order.card_id} order={order} onCheckInfo={handleCheckInfo} />
                    ))}
                </tbody>
            </table>
        </>
    );

    const OrderRow = ({ order }: { order: ListCards; onCheckInfo: (order: ListCards) => void }) => (
        <tr>
            <td>{order.card_type}</td>
            <td>{order.card_id}</td>
            <td>{order.order_status}</td>
            <td>{getActionButton(order)}</td>
            <td>{order.order_reference}</td>
        </tr>
    );

    const getActionButton = (order: ListCards) => {
        if (order.order_status === StatusCardOrders.MAIL_KIT_ERROR && order.card_type === cardTypes.PREMIUM && !hasCustomOrders) {
            return <ActionButton label="Réessayer l'envoi du Mail Kit" onClick={() => handleEmailRetry(order)} />;
        }

        if (order.order_status === StatusCardOrders.MAIL_KIT_PENDING && order.card_type === cardTypes.PREMIUM && !hasCustomOrders) {
            return <ActionButton label="Vérifier les informations" onClick={() => handleCheckInfo(order)} />;
        }

        return null;
    };

    const ActionButton = ({ label, onClick }: { label: string; onClick: () => void }) => (
        <button onClick={onClick}>{label}</button>
    );

    const handleCheckInfo = (order: ListCards) => {
        setSelectedCard(order);
        setIsInfoBoxOpen(true);
    };

    const handleEmailRetry = (order: ListCards) => {
        updateCardOrderStatus(StatusCardOrders.MAIL_KIT_SENT, order.card_id);
    };

    const handleExportCards = async (orderReference: string) => {
        if (!orderReference) {
            console.log('Aucune commande à exporter.');
            return;
        }

        try {
            const result = await exportCardsExcel(orderReference);
            if (result.length > 0) {
                console.log('Exportation des cartes réussie.');
                await handleCardsDeletion(orderReference);
            } else {
                console.log('Aucune carte à exporter.');
            }
        } catch (error) {
            console.error('Erreur lors de l\'exportation des cartes:', error);
        }
    };

    const handleCardsDeletion = async (orderReference: string) => {
        const json = await fetchCardsByOrderReference(orderReference);
        await Promise.all(json.map(card => updateCardOrderStatus(StatusCardOrders.DELETE, card!.cardId)));
        console.log('Mise à jour du statut des cartes réussie.');
    };

    const handleValidateCard = async (updateStatus: string, cardId: string) => {
        try {
            const { error } = await supabase.from('card_orders').update({ order_status: updateStatus }).eq('card_id', cardId);
            if (error) {
                console.error(`Erreur lors de la mise à jour du statut de la commande : ${error.message}`);
            } else {
                console.log(`Mise à jour du statut réussie pour la carte ${cardId}`);
                setIsInfoBoxOpen(false);
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du statut :", error);
        }
    };

    const renderInfoBox = () => {
        if (!selectedCard) return null;

        const { customer_last_name, customer_first_name, card_picture, card_color } = selectedCard;

        return (
            <div className="info-box">
                <h2>Détails de la carte : {selectedCard.card_id}</h2>
                <p>Nom du client: {customer_last_name}</p>
                <p>Prénom du client: {customer_first_name}</p>
                <p>Couleur de la carte: {card_color}</p>
                {card_picture ? (
                    <div>
                        <img src={card_picture} alt="Rendu de la carte" style={{ width: '100px', height: 'auto' }} />
                        <a href={card_picture} download style={{ display: 'block', marginTop: '10px' }}>Télécharger l'image</a>
                    </div>
                ) : (
                    <p>Aucune image disponible</p>
                )}
                <div>
                    <ActionButton label="Valider" onClick={() => handleValidateCard(StatusCardOrders.MAIL_KIT_SUCCES, selectedCard.card_id)} />
                    <ActionButton label="Refuser" onClick={() => handleValidateCard(StatusCardOrders.MAIL_KIT_ERROR, selectedCard.card_id)} />
                </div>
                <ActionButton label="Fermer" onClick={() => setIsInfoBoxOpen(false)} />
            </div>
        );
    };

    const renderActionButton = (condition: boolean, onClick: () => Promise<void>, label: string) => (
        condition && (
            <div className="central-action">
                <ActionButton label={label} onClick={onClick} />
            </div>
        )
    );

    return (
        <div className="order-details">
            {basicsOrders.length > 0 && renderTable(`Liste des ${cardTypes.BASIC}`, basicsOrders)}
            {premiumsOrders.length > 0 && renderTable(`Liste des ${cardTypes.PREMIUM}`, premiumsOrders)}

            {renderActionButton(allBasicsCreated && !anyPremiumsPresent && !hasCustomOrders,
                () => processEmailForCards(cardTypes.BASIC, StatusCardOrders.FINISH, basicsOrders[0]?.order_reference || '', basicsOrders[0]?.customer_email || ''),
                "Envoi Token & ID pour Basic Cards"
            )}

            {renderActionButton(premiumsOrders.some(order => order.order_status === StatusCardOrders.CREATED && !hasMailKitError && !hasCustomOrders),
                () => processEmailForCards(cardTypes.PREMIUM, StatusCardOrders.MAIL_KIT_SENT, premiumsOrders[0]?.order_reference || '', premiumsOrders[0]?.customer_email || ''),
                "Envoyer Mail Kit pour Premium Cards"
            )}

            {renderActionButton(allBasicsCreated && allPremiumsMailKitSuccess && premiumsOrders.length > 0 && !hasCustomOrders,
                () => processEmailForCards(cardTypes.BASIC, StatusCardOrders.FINISH, basicsOrders[0]?.order_reference || '', basicsOrders[0]?.customer_email || ''),
                "Envoyer Token & ID pour toutes les cartes"
            )}

            {renderActionButton(allPremiumsMailKitSuccess && basicsOrders.length <= 0 && !hasCustomOrders,
                () => processEmailForCards(cardTypes.BASIC, StatusCardOrders.FINISH, premiumsOrders[0]?.order_reference || '', premiumsOrders[0]?.customer_email || ''),
                "Envoyer Token & ID pour toutes les cartes"
            )}

            {renderActionButton(hasCustomOrders && !allCardsFinished && !hasDeleteCards, () => processEmailForCards(cardTypes.BASIC, StatusCardOrders.FINISH, customOrders[0].order_reference || '', customOrders[0].customer_email || ''),
                "Envoyer Token & ID pour Custom Cards"
            )}

            {renderActionButton((allCardsFinished && !hasMailKitPending && !hasMailKitSent) || hasDeleteCards,
                async () => handleExportCards(referenceCard || ''),
                "Exporter les cartes"
            )}

            {renderActionButton(hasDeleteCards,
                async () => {
                    await Promise.all(
                        orders
                            .filter(order =>
                                order.order_status === StatusCardOrders.DELETE
                            )
                            .map(order => deleteCardsOrder(order.order_reference as string))
                    );
                    alert("Les commandes ont été supprimées avec succès !");
                },
                "Supprimer les commandes"
            )}

            {isInfoBoxOpen && renderInfoBox()}
        </div>
    );
};

export default OrderDetails;
