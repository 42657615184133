import { fetchCardsByOrderReference } from '../hooks/fetchCardsByOrderReference';
import { StatusCardOrders } from '../types/cardOrders/statusCardOrders';
import { cardTypes } from '../types/cardOrders/cardTypes';
import { supabase } from './supabaseClient';

export const batchUpdateCardOrderStatus = async (updateStatus: string, orderReference: string) => {
    const jsonResult = await fetchCardsByOrderReference(orderReference);
    const priority = jsonResult.some(card => card !== null && card.cardType as string === cardTypes.PREMIUM) 
        && jsonResult.some(card => card !== null && card.cardType as string === cardTypes.BASIC) 
        && updateStatus === StatusCardOrders.MAIL_KIT_SENT;
    const validCards = jsonResult.filter(card => card !== null);
    
    if (priority) {
        for (const card of validCards) {
            if (!card) continue;
            if (card.cardType === cardTypes.PREMIUM) {
                await updateCardOrderStatus(updateStatus, card.cardId);
            }
        }
    } else {
        for (const card of validCards) {
            if (!card) continue;
            await updateCardOrderStatus(updateStatus, card.cardId);
        }
    }
};

export const updateCardOrderStatus = async (updateStatus: string, cardId: string) => {
    try {
        const { error } = await supabase
            .from('card_orders')
            .update({ order_status: updateStatus })
            .eq('card_id', cardId);
        if (error) {
            console.error(`Erreur lors de la mise à jour du statut de la commande : ${error.message}`);
        }
    } catch (error) {
        console.error("Erreur lors de la mise à jour du statut :", error);
    }
};
