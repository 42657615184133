import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { User } from '../types/user/user';
import { fetchUserByUsername } from '../services/userService';

import BannerImage from '../components/BannerImage';
import ContactForm from '../components/ContactForm';
import Name from '../components/Name';
import Description from '../components/Description';
import SocialLinksComponent from '../components/SocialLinks';
import CustomLinks from '../components/CustomLinks';
import LoadingScreen from '../components/LoadingScreen';
import Error from '../components/Error';

import '../styles/pages/Profile.css';

const Profile: React.FC = () => {
    const { username } = useParams<{ username: string }>();
    const [user, setUser] = useState<User | null>(null);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleUserFetch = useCallback(async () => {
        if (!username) return;

        setIsLoading(true);
        setTimeout(async () => {
            const fetchedUser = await fetchUserByUsername(username);
            if (!fetchedUser) {
                setError("L'utilisateur recherche n'existe pas ou le lien fourni est incorrect. Veuillez verifier les informations et reessayer.");
            } else {
                setUser(fetchedUser);
            }
            setIsLoading(false);
        }, 1000);
    }, [username, navigate]);

    useEffect(() => {
        handleUserFetch();
    }, [handleUserFetch]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (error) {
        return <Error error={error} />;
    }

    return (
        <div className='profile-container'>
            <BannerImage
                bannerUrl={user?.bannerPictureUrl}
                profileUrl={user?.profilePictureUrl}
            />
            <ContactForm
                firstName={user?.first_name}
                lastName={user?.last_name}
                phone={user?.phoneNumber}
                email={user?.email}
            />
            <Name
                firstName={user?.first_name}
                lastName={user?.last_name}
            />
            <Description
                description={user?.bio}
            />
            <SocialLinksComponent
                instagramUrl={user?.socialLinks?.instagram}
                linkedinUrl={user?.socialLinks?.linkedin}
                facebookUrl={user?.socialLinks?.facebook}
                twitterUrl={user?.socialLinks?.twitter}
                tiktokUrl={user?.socialLinks?.tiktok}
            />
            <CustomLinks
                customLinks={user?.socialLinks?.customLinks}
            />
        </div>
    );
};

export default Profile;
