import React, { useState } from 'react';
import { supabase } from '../../services/supabaseClient';
import '../../styles/components/auth/Login.css';

interface LoginProps {
    onLogin: (userId: string) => Promise<void>;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);

    const validateEmail = (email: string): boolean => {
        return email.includes('@') && email.length > 0;
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        if (validateEmail(email)) {
            const { data, error } = await supabase.auth.signInWithPassword({
                email,
                password,
            });

            if (error) {
                setError(error.message);
            } else if (data.user) {
                onLogin(data.user.id);
            }
        } else {
            setError("L'email n'est pas valide.");
        }
    };

    return (
        <div className="container">
            <form onSubmit={handleLogin} className="form">
                {error && <p className="error">{error}</p>}
                <div>
                    <label>
                        Email:
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="input"
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Mot de passe:
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input"
                        />
                    </label>
                </div>
                <button type="submit" className="button">
                    Se connecter
                </button>
            </form>
        </div>
    );
};

export default Login;
