import React from 'react';
import '../styles/components/Error.css';
import logo from '../assets/images/logo_name.png';

interface ErrorProps {
    error: string | null;
}

const Error: React.FC<ErrorProps> = ({ error }) => {
    if (!error) {
        return null;
    }

    return (
        <div className='error-container'>
            <img src={logo} alt="Logo" className="error-logo" />
            <span className="error-title">Page Introuvable</span>
            <p className="error-message">{error}</p>
        </div>
    );
};

export default Error;
