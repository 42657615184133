import React, { useState } from 'react';
import { supabase } from '../../services/supabaseClient';

interface Card {
  card_id: string;
  token: string;
  order_reference: string;
}

const CardAddCustomer: React.FC = () => {
  const [showForm, setShowForm] = useState(false);
  const [basicCount, setBasicCount] = useState<number>(0);
  const [premiumCount, setPremiumCount] = useState<number>(0);
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleButtonClick = () => {
    setShowForm(true);
  };

  const resetForm = () => {
    setBasicCount(0);
    setPremiumCount(0);
    setEmail('');
    setPhone('');
    setError('');
  };

  const getNextOrderReference = async (): Promise<string> => {
    const { data, error } = await supabase
      .from('cards')
      .select('order_reference')
      .order('order_reference', { ascending: false })
      .limit(1);

    if (error) {
      throw new Error('Erreur lors de la récupération de la référence de commande: ' + error.message);
    }

    if (data.length > 0) {
      const latestReference = data[0].order_reference;
      const match = latestReference.match(/custom#(\d+)/);
      if (match && match[1]) {
        const currentNumber = parseInt(match[1], 10);
        return `custom#${currentNumber + 1}`;
      }
    }

    return 'custom#1';
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (basicCount === 0 && premiumCount === 0) {
      setError('Veuillez entrer au moins une carte Basic ou Premium.');
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError('Veuillez entrer une adresse email valide.');
      return;
    }

    const phonePattern = /^\+?[0-9]{10,14}$/;
    if (phone !== '' && !phonePattern.test(phone)) {
      setError('Veuillez entrer un numéro de téléphone valide.');
      return;
    }

    try {
      const cardsToInsert: Card[] = [];
      const cardOrdersToInsert: any[] = [];

      const orderReference = await getNextOrderReference();

      for (let i = 0; i < basicCount; i++) {
        const { card_id, token } = await generateUniqueCardIdAndToken(cardsToInsert);
        cardsToInsert.push({ card_id, token, order_reference: orderReference });
        cardOrdersToInsert.push({
          card_id,
          card_type: 'Pocket Basics',
          order_status: 'created',
          customer_email: email,
          customer_phone: phone || null,
          order_reference: orderReference,
          order_date: new Date().toISOString(),
        });
      }

      for (let i = 0; i < premiumCount; i++) {
        const { card_id, token } = await generateUniqueCardIdAndToken(cardsToInsert);
        cardsToInsert.push({ card_id, token, order_reference: orderReference });
        cardOrdersToInsert.push({
          card_id,
          card_type: 'Pocket Premium',
          order_status: 'created',
          customer_email: email,
          customer_phone: phone || null,
          order_reference: orderReference,
          order_date: new Date().toISOString(),
        });
      }

      if (cardsToInsert.length > 0) {
        const { error: cardInsertError } = await supabase.from('cards').insert(cardsToInsert);
        if (cardInsertError) {
          throw new Error('Erreur lors de l\'insertion des cartes: ' + cardInsertError.message);
        }
      }

      if (cardOrdersToInsert.length > 0) {
        const { error: orderInsertError } = await supabase.from('card_orders').insert(cardOrdersToInsert);
        if (orderInsertError) {
          throw new Error('Erreur lors de l\'insertion des commandes: ' + orderInsertError.message);
        }
      }

      resetForm();
      setShowForm(false);

    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <div className="header-left">
      <button className="header-left-button" onClick={handleButtonClick}>
        Ajout de carte personnalisée<br />
        manuellement
      </button>

      {showForm && (
        <>
          <div className="overlay" style={overlayStyle}></div>
          <div className="modal" style={modalStyle}>
            <form onSubmit={handleFormSubmit} style={formStyle}>
              <div>
                <label htmlFor="basicCount">Basic:</label>
                <input
                  type="number"
                  id="basicCount"
                  value={basicCount}
                  onChange={(e) => setBasicCount(Number(e.target.value) || 0)}
                />
              </div>
              <div>
                <label htmlFor="premiumCount">Premium:</label>
                <input
                  type="number"
                  id="premiumCount"
                  value={premiumCount}
                  onChange={(e) => setPremiumCount(Number(e.target.value) || 0)}
                />
              </div>
              <div>
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor="phone">Téléphone:</label>
                <input
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              {error && <p className="error">{error}</p>}
              <button type="submit" style={{ backgroundColor: 'green', color: 'white' }}>Soumettre</button>
              <button type="button" onClick={() => { resetForm(); setShowForm(false); }} style={{ backgroundColor: 'red', color: 'white' }}>Fermer</button>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

const modalStyle: React.CSSProperties = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '8px',
  padding: '20px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  zIndex: 1000,
};

const formStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
};

const overlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(8px)',
  zIndex: 999,
};


async function generateUniqueCardIdAndToken(cardsToInsert: Card[]): Promise<Card> {
  let card_id: string = '';
  let token: string = '';
  let exists = true;

  while (exists) {
    card_id = generateRandomString(8);
    token = generateToken(6);
    exists = cardsToInsert.some(card => card.card_id === card_id || card.token === token);
  }

  return { card_id, token, order_reference: '' };
}

function generateRandomString(length: number): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

function generateToken(length: number): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export default CardAddCustomer;
