import React from "react";
import '../styles/components/ContactForm.css';

import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';

interface ContactFormProps {
    firstName: string | null | undefined;
    lastName: string | null | undefined;
    phone: string | null | undefined;
    email: string | null | undefined;
}

const generateVCard = (firstName: string, lastName: string, phoneNumber: string, email: string | null | undefined) => {
    return `BEGIN:VCARD
VERSION:3.0
FN:${firstName} ${lastName}
TEL;TYPE=CELL:${phoneNumber}
EMAIL:${email || ""}
END:VCARD`;
};

const ContactForm: React.FC<ContactFormProps> = ({ firstName, lastName, phone, email }) => {
    const handleClick = () => {
        if (!firstName) {
            window.alert("Vous ne pouvez pas ajouter cette personne, elle n'a pas renseigné son prénom.");
            return;
        }

        if (!lastName) {
            window.alert("Vous ne pouvez pas ajouter cette personne, elle n'a pas renseigné son nom.");
            return;
        }

        if (!phone) {
            window.alert("Vous ne pouvez pas ajouter cette personne, elle n'a pas renseigné son numéro de téléphone.");
            return;
        }

        const vCardData = generateVCard(firstName, lastName, phone, email);
        const blob = new Blob([vCardData], { type: 'text/vcard' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${firstName || "contact"}.vcf`;
        a.click();

        window.URL.revokeObjectURL(url);
    };

    return (
        <button className="contact-button" onClick={handleClick}>
            <div className="button-row">
                <span className="contact-text">Fiche contact</span>
                <ContactlessOutlinedIcon className="contact-icon" />
            </div>
        </button>
    );
};

export default ContactForm;