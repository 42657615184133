
export const generateHtmlContent = (jsonResult: any[], isPremium: boolean) => {
    if (isPremium) {
        return `
            <h3>Détails de la commande PREMIUM</h3>
            <p>Voici la procédure pour finaliser votre commande :</p>
            <p>1. Veuillez cliquer sur le lien suivant pour finaliser votre commande : <a href="https://www.example.com">Lien personnalisé</a></p>
            <p>Merci de votre commande !</p>
        `;
    } else {
        return `
            <h3>Détails de la commande</h3>
            <p>Voici les détails de votre commande :</p>
            <ul>
                ${jsonResult.map((card, index) => `
                    <li>
                        ${index + 1}. <strong>${card.cardType}</strong> - Card ID : <strong>${card.cardId}</strong> - Token : <strong>${card.token}</strong><br>
                    </li>
                `).join('')}
            </ul>
            <p>Merci de votre commande !</p>
        `;
    }
};
