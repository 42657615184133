import { supabase } from './supabaseClient';

interface FetchCardLinkResult {
    link: string | null;
    error: 'invalid_id' | 'empty_link' | null;
}

export async function fetchCardLinkById(cardId: string): Promise<FetchCardLinkResult> {
    const { data: cardData, error } = await supabase
        .from('cards')
        .select('username_link')
        .eq('card_id', cardId)
        .single();

    if (error) {
        console.error(`Error fetching link for card ID: ${cardId}`, error);
        return { link: null, error: 'invalid_id' };
    }
    if (!cardData || !cardData.username_link) {
        return { link: null, error: 'empty_link' };
    }

    return { link: cardData.username_link, error: null };
}
